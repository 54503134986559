import {
    BRANDS,
    cookieBannerPolicyLinks as plasticCookieBannerPolicyLinks,
    FullPageCookieBanner,
    CookieBanner
} from '@cof/plastic-components';
import { useFullPageCookieBannerExperiment } from '../../hooks/useExperiment/useFullPageCookieBannerExperiment';

const TransformedCookieBanner = (props) => {
    const fullPageCookieBannerExperiment = useFullPageCookieBannerExperiment();
    const policyLinks = Object.entries(plasticCookieBannerPolicyLinks).reduce(
        (updatedPolicyLinks, [brand, link]) => ({
            ...updatedPolicyLinks,
            [brand === BRANDS.CAPITAL_ONE ? 'capitalOne' : brand.toLowerCase()]: link
        }),
        {}
    );

    if (fullPageCookieBannerExperiment) {
        return <FullPageCookieBanner policyLinks={policyLinks} isOpen={true} {...props} />;
    }
    return <CookieBanner policyLinks={policyLinks} {...props} />;
};

export default TransformedCookieBanner;
