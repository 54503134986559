import PropTypes from 'prop-types';
import React from 'react';

import ErrorContext from '../../utilities/errorContext';
import { addNewRelicError } from '../../utilities/newRelic';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error) {
        addNewRelicError(error, { clientTimestamp: Date.now(), sawError: true });
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        const { renderOnError, children } = this.props;

        if (hasError) {
            return renderOnError;
        }

        return (
            <ErrorContext.Provider
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{
                    setError: () => {
                        this.setState({ hasError: true });
                    }
                }}
            >
                {children}
            </ErrorContext.Provider>
        );
    }
}

ErrorBoundary.propTypes = {
    renderOnError: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired
};

export default ErrorBoundary;
