import { Box } from '@cof/plastic-components';

import aggregatorsLogoMap from '../Logo/AggregatorsLogo';
import { useJourneyContext } from '../../data/JourneyContext';

const AggregatorLogo = () => {
    const { aggregatorConsumerName } = useJourneyContext();

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {aggregatorConsumerName && (
                <Box paddingLeft="10px" data-qa-id="default-aggregator-logo">
                    {aggregatorsLogoMap[aggregatorConsumerName]}
                </Box>
            )}
        </>
    );
};

export default AggregatorLogo;
