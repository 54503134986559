import { ERROR_TYPE } from '../../utilities/constants';

const ExternalLinks = {
    QUICKCHECK: 'https://quickcheck.capitalone.co.uk',
    CREDIT_CARDS: 'https://www.capitalone.co.uk/creditcards/credit-cards',
    CONTACT_US: 'https://www.capitalone.co.uk/support/contact-us',
    PRIVACY_POLICY: 'https://www.capitalone.co.uk/compliance/privacy-policy'
};

const commonProductAttributes = {
    Feature1: ' {{MAX_CREDIT_LIMIT}}',
    Feature2: '{{APR}} representative variable',
    Feature3: '{{INTRODUCTORY_RATE}} on balance transfers for {{TRANSFER_MONTHS}}',
    Feature4: '{{TRANSFER_FEE}} balance transfer fee',
    Feature5: '{{INTRODUCTORY_RATE}} on purchases for {{PURCHASE_MONTHS}}'
};

const lineGuaranteeAttributes = {
    Feature6: 'Minimum {{MAX_CREDIT_LIMIT}} credit limit',
    Feature7: 'Up to {{MAX_CREDIT_LIMIT}} when you apply'
};

const content = {
    DocumentHead: {
        Title: 'Credit Card Application - Capital One',
        Description: 'Complete our application form for a Capital One credit card. Response in 60 seconds.'
    },
    Pages: {
        ProductChoice: {
            HeadingLine1Dynamic: "It's a yes, {{CUSTOMER_NAME}}!",
            PreApprovedMessage:
                "You're pre-approved for {{NUMBER_OF_CARDS}} of our cards, choose the one that's right for you.",
            CTA: 'Continue with this card'
        },
        Landing: {
            HeadingLine1: "It's a yes!",
            HeadingLine1Dynamic: "It's a yes, {{CUSTOMER_NAME}}!",
            HeadingLine2: 'Finish your application below',
            BTGuaranteeLine2: 'Your {{REQUESTED_AMOUNT}} balance transfer is guaranteed',
            BTNonGuaranteeLine2:
                "You're accepted for a balance transfer card, but we can't guarantee your {{REQUESTED_AMOUNT}} transfer",
            BTNonGuaranteeLine3: 'You could still apply with a lower transfer amount.',
            ClassicComplete: 'Classic Complete',
            BT: 'Balance Transfer'
        },
        TechnicalError: {
            [ERROR_TYPE.CLIENT_ERROR]: {
                Title: 'There was a problem loading the application form',
                List: {
                    Description: 'It should be quick to fix. To finish your application, you can either:',
                    Item1: 'Check your inbox for an email from us that drops you on the application form.',
                    Item2: 'Do another QuickCheck.'
                },
                Button: { Text: 'Do another QuickCheck', URL: ExternalLinks.QUICKCHECK }
            },
            [ERROR_TYPE.LOAD_AGREEMENT]: {
                Title: 'There was a problem loading the page',
                List: {
                    Description: 'Sorry about this. There are a couple of things you can do:',
                    Item1: 'Try the link again now.',
                    Item2: 'Give the link another go later on.'
                },
                Paragraph: 'If this keeps happening, you might need to do another QuickCheck.',
                Button: { Text: 'Do another QuickCheck', URL: ExternalLinks.QUICKCHECK }
            },
            [ERROR_TYPE.SUBMIT_AGREEMENT]: {
                Title: 'We had a problem processing your application',
                Paragraph1:
                    'Sorry about this. There’s a chance that your application has gone through fine. If so, you should get an email soon to confirm things.',
                Paragraph2: 'But if you haven’t heard from us in the next few hours, then please try again.'
            }
        },
        TimedOut: {
            Title: "We've sent you a link to continue",
            Paragraph1:
                'Your session timed out, but we’ve saved your progress so far. To finish your application, follow the link we sent you by email or text.',
            Paragraph2: 'Or, you can start a new QuickCheck with no impact to your credit score.',
            Button: { Text: 'Do another QuickCheck', URL: ExternalLinks.QUICKCHECK }
        }
    },
    Sections: {
        Product: {
            Title: 'Your offer',
            Representative:
                'Representative example: Assuming a credit limit of {{CREDIT_LIMIT}} and an interest rate on purchases of {{INTEREST_RATE}} p.a. variable, you will receive a {{APR}} APR representative variable.',
            MoreInformation: 'For more information, check the {{SUMMARY_BOX}} and {{FAQs}}.',
            Text1: 'Your credit limit is subject to final checks. ',
            Features: {
                ...commonProductAttributes,
                ...lineGuaranteeAttributes,
                Feature8: 'Get your card details in the app and use it straight away'
            },
            ReasonsToBelieve: {
                1: { Heading: 'Award-winning mobile app', Body: 'Packed with useful features' },
                2: { Heading: 'No yearly membership fee', Body: 'Get a card without the annual cost' },
                3: { Heading: 'Flexible credit limits', Body: 'Manage your limit from the app' }
            }
        },
        CheckDetails: {
            Intro: 'Please check your details and read through the information in full before applying',
            Accordion: { Heading: { WhenOpen: 'Hide my details', WhenClosed: 'Check my details' } },
            Title: 'Check your details are 100% right',
            DataPlayback: {
                BTAmount: 'balance transfer requested',
                BornOn: 'born on',
                Income: 'pre-tax yearly income',
                OtherIncome: 'other household income',
                Dependents: 'financial dependents',
                CashAdvance: { Outcome: { Yes: 'Interested', No: 'Not interested' }, Label: 'in cash advance' },
                At: 'at',
                Contribution: 'rent or mortgage contribution',
                YearsAtAddress: 'at this address',
                PreviousAddress: 'Previously at'
            }
        },
        Preferences: {
            Title: 'Set up your preferences',
            MonthlyStatement: { Heading: 'Monthly statements', Label: 'Sign up to online only statements' }
        },
        BalanceTransfer: {
            Title: 'Balance Transfers',
            SubHeading: 'Would you like to transfer an existing balance to your Capital One card now?',
            Paragraph1: 'This is optional and you can do this at a later date by calling when your card arrives',
            Paragraph2:
                'If you would like to transfer an existing balance to your new Capital One card, please enter your current card details below. Minimum transfer amount is £50',
            Info1: 'The total amount entered is over your balance transfer limit',
            Info2: 'You have {{BTAMOUNTREMAINING}} remaining of your {{BTTOTAL}} balance transfer limit',
            Buttons: { Cancel: 'Remove this transfer', Add: 'Add another transfer' },
            Inputs: { CardNumber: 'Card number to transfer from', TransferAmount: 'Transfer amount' },
            Paragraph3: {
                Text: 'You can learn more about our {{BTTerms}}',
                BTTerms: 'Balance transfer terms and conditions'
            },
            Errors: {
                CheckCardNo: 'Please check you have entered the card number correctly',
                CardNoRequired: 'A card number is required',
                TransferAmountRequired: 'A transfer amount is required',
                TransferTooLow: 'The minimum transfer amount is £50',
                TransferTooHigh:
                    'This is over what we can transfer to this card, so we might not be able to accept it all',
                TransferTooLong: 'Balance transfer amount must be a max of 7 digits',
                TransferAboveApproved:
                    'This is over your guaranteed balance transfer amount, so we might not be able to accept it all',
                NoError: ''
            }
        },
        MissingDetails: {
            Title: 'Add your details',
            Fields: {
                Title: 'Title',
                EmailAddress: 'Email address',
                PhoneNumber: {
                    Heading: 'Phone numbers (at least one required)',
                    Mobile: 'Mobile number',
                    InfoPanel: "You'll need your mobile phone number to access the app and your online account",
                    Button: 'Add home number (optional)',
                    Home: 'Home number',
                    Subtext: "You'll need a phone number to access the app and online account"
                },
                Occupation: 'Occupation',
                OtherHouseholdIncome: {
                    Title: 'Other yearly household income (for example, £21,500)',
                    Subtitle:
                        'The pre-tax income of anyone living at your address with a financial connection to you (e.g. joint bank accounts, mortgages, credit cards)',
                    Accordian: {
                        Trigger: 'Could this income change soon?',
                        Content:
                            'If this income could <span style={{fontWeight: "bolder"}}>go down</span> in the next 12 months, please enter what you think it will be.'
                    }
                }
            },
            Errors: {
                Title: { Required: 'Please select an option for title' },
                Email: { Required: 'Email address is required', Invalid: 'This is not a valid email address' },
                Phone: {
                    Required: 'At least one number is required',
                    Mobile: {
                        Length: 'Mobile number must be 11 digits',
                        Valid: 'Mobile number must be a valid UK Mobile phone number'
                    },
                    Home: {
                        MinLength: 'Home number must be at least 10 digits',
                        MaxLength: 'Home number must be at most 11 digits',
                        Valid: 'Home number must be a valid UK Home phone number'
                    }
                },
                Occupation: {
                    Invalid: 'This is not a valid occupation',
                    Length: 'Occupation must be 32 characters or less'
                },

                OtherIncome: {
                    Required: 'Other household income is required',
                    Maximum: 'Yearly income must be a max of 7 digits',
                    Invalid: 'This is not a valid yearly income'
                }
            }
        },
        UsingYourCard: {
            Title: 'Things to remember about getting a card',
            Heading: 'This can help you make sure that getting a credit card is the right choice.',
            Button: 'Download or print section'
        },
        PrecontractInformation: {
            Title: 'Your pre-contract information',
            Heading: 'This pulls out key features and terms, so you can compare our card to others.',
            Button: 'Download or print section',
            AccordionExperiment: {
                Title: 'Your pre-contract information',
                Heading: 'This pulls out key features and info, so you can compare our card to others.',
                Button: 'Download or print section',
                Closed: 'Show key features',
                Open: 'Hide key features'
            }
        },
        OtherThings: {
            Title: 'Other things you should know',
            Paragraph0: 'When you submit your application, we use your info to:',
            List0: {
                0: 'Check your eligibility again with the credit reference agencies, and let them know you’ve applied for a credit card. This will go on your credit file.',
                1: 'Do our fraud and identity checks. Depending on what comes back, there’s a small chance we won’t be able to open your account.',
                Mobile1:
                    'Do our identity, affordability, fraud, and regulatory checks. Depending on what comes back, there’s a small chance you won’t be able to open an account or spend with your card before it arrives.'
            },
            Paragraph1: 'Then when you’ve got your card, we use it to:',
            List1: {
                0: 'Stay up to date with your full financial health. We do this by checking your credit score and other info from time to time. This helps us decide things like when to offer you a credit limit increase.'
            },
            Paragraph2: 'For more information, check the {{SUMMARY_BOX}} and {{FAQs}}',
            Paragraph3: {
                Text: 'For more about how we use your information, and your data protection rights, check our {{PRIVACY_POLICY}}',
                PrivacyPolicy: 'Privacy Policy',
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            },
            MarketingCheckbox:
                'We may get in touch about products, services and relevant information by post, email or SMS. To say no, untick the box',
            OnlineStatementsCheckbox: 'Sign up to paperless statements'
        },
        AgreementSign: {
            Title: 'Your credit agreement',
            Heading:
                'Please review your Credit Card Agreement below. This will only become legally binding if your application is accepted.',
            SignatureBlock: {
                Description:
                    'This is a Credit Agreement regulated by the Consumer Credit Act 1974. Required. Please tick the box below only if you want to be legally bound by its terms.',
                Signature: { Text: 'Signature of customer' },
                Error: 'Please select the sign agreement tick box.',
                Date: 'Dated: {{DATE}}'
            },
            Button: 'Download or print section',
            FinishYourApplication: 'Finish your application',
            FinishButtonText: 'Agree and submit',
            Finish: 'I understand this is a Credit Agreement regulated by the Consumer Credit Act 1974 and agree to be legally bound by its terms.',

            PlaceholderText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget gravida cum sociis natoque penatibus. Vitae nunc sed velit dignissim sodales ut eu sem. Ultrices dui sapien eget mi proin sed libero enim. Vulputate odio ut enim blandit volutpat. Sollicitudin nibh sit amet commodo. Convallis tellus id interdum velit laoreet id donec ultrices tincidunt. Nunc consequat interdum varius sit. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Eu scelerisque felis imperdiet proin fermentum. Quis hendrerit dolor magna eget est lorem ipsum.

            Ac placerat vestibulum lectus mauris ultrices. Felis donec et odio pellentesque diam. Eget velit aliquet sagittis id. Placerat in egestas erat imperdiet sed. Id donec ultrices tincidunt arcu non sodales neque sodales. Magna eget est lorem ipsum dolor sit amet. Lobortis scelerisque fermentum dui faucibus in ornare. Diam sollicitudin tempor id eu nisl nunc. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam erat. Sit amet volutpat consequat mauris nunc congue. Arcu ac tortor dignissim convallis aenean et tortor. Nibh nisl condimentum id venenatis a condimentum vitae.

            Id neque aliquam vestibulum morbi blandit cursus. Eget nullam non nisi est sit. Sit amet consectetur adipiscing elit pellentesque. Ultrices dui sapien eget mi. Aliquet bibendum enim facilisis gravida neque convallis a. Id cursus metus aliquam eleifend mi. Nunc eget lorem dolor sed viverra ipsum nunc. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. In eu mi bibendum neque egestas. Sed libero enim sed faucibus turpis in. In hac habitasse platea dictumst. Molestie a iaculis at erat pellentesque adipiscing commodo elit at. Aenean euismod elementum nisi quis eleifend. Nulla facilisi cras fermentum odio eu feugiat pretium. Vel facilisis volutpat est velit egestas dui id ornare arcu. Enim nunc faucibus a pellentesque sit amet porttitor eget. Non blandit massa enim nec dui. Posuere morbi leo urna molestie at elementum eu facilisis. Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis a. Vulputate enim nulla aliquet porttitor lacus luctus accumsan.

            Mauris cursus mattis molestie a iaculis at. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Pellentesque sit amet porttitor eget dolor morbi non arcu risus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis. Ullamcorper a lacus vestibulum sed arcu non. Tortor posuere ac ut consequat semper viverra nam libero justo. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Ullamcorper a lacus vestibulum sed. Rhoncus dolor purus non enim praesent elementum facilisis. Pellentesque habitant morbi tristique senectus et. Ridiculus mus mauris vitae ultricies leo.

            Ultricies mi quis hendrerit dolor magna eget. Ut ornare lectus sit amet. Ornare lectus sit amet est placerat in egestas. Velit ut tortor pretium viverra. Tristique nulla aliquet enim tortor at auctor. Cursus eget nunc scelerisque viverra mauris in. Eu ultrices vitae auctor eu augue. Aliquam sem et tortor consequat id. In massa tempor nec feugiat nisl pretium fusce. Nulla facilisi morbi tempus iaculis urna id. Nulla malesuada pellentesque elit eget gravida cum. Nunc eget lorem dolor sed. Elit sed vulputate mi sit amet. Tellus orci ac auctor augue mauris augue neque gravida. Habitant morbi tristique senectus et netus. In est ante in nibh mauris. Leo vel orci porta non pulvinar. Consectetur purus ut faucibus pulvinar elementum integer enim neque. Justo eget magna fermentum iaculis. Laoreet suspendisse interdum consectetur libero id faucibus.`
        },
        Finish: {
            Title: 'How we use your information',
            Paragraph1: 'Once you submit your application, we still need to:',
            ListItem1:
                "Check your eligibility again with the credit reference agencies and let them know you've applied for a credit card. This will go on your credit file.",
            ListItem2:
                'Do our fraud and identity checks. To do this, we share your information with fraud prevention agencies and other parties. Depending on what comes back, there’s a small chance we won’t be able to open your account.',
            Paragraph2: 'Then, if you’re approved, we will continue to use your information to:',
            ListItem3:
                'Stay up to date with your full financial health by checking your credit file and other information from time to time. This helps us to decide things such as when to offer you a credit limit increase.',
            Paragraph3: {
                Text: 'For more about how we use your information and your data protection rights, check our {{PRIVACY_POLICY}}',
                PrivacyPolicy: 'Privacy Policy',
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            },
            MarketingPreference: {
                Text: 'We may get in touch about products, services and relevant information by post, email or SMS. To say no, untick the box.'
            },
            Button: { Text: 'Finish my application' }
        }
    },
    Modals: {
        SummaryBox: { Heading: 'Key features of your Capital One credit card', TriggerText: 'Summary Box' },
        FAQs: {
            Heading: 'FAQs about your credit card application.',
            TriggerText: 'questions about your credit card application',
            AddendumLine1: `If we haven't been able to answer your question above, then please call us on {{LINK}}.`,
            AddendumLine2: `Lines are open 9am – 5pm Mon – Fri`,
            PhoneNumber: '0800 952 5252',
            Questions: {
                0: {
                    Heading: 'Can I apply for a {{PARTNER}} credit card?',
                    Answer: 'Each {{PARTNER}} credit card has criteria to give you an idea of who is suitable for that type of card. Take a {{LINK}} to see which one suits you.',
                    Link: { Text: 'look at our credit cards', Url: ExternalLinks.CREDIT_CARDS }
                },
                1: {
                    Heading: 'I already have a {{PARTNER}} card. Can I apply for another one?',
                    Answer: `Yes, if you've had a {{PARTNER}} account for over four months you can also apply for some of our latest products. {{LINK}} to see if one of these great cards also suits you.`,
                    Link: { Text: 'Take a look', Url: ExternalLinks.CREDIT_CARDS }
                },
                2: {
                    Heading: 'What information will you need from me to apply for a {{PARTNER}} credit card?',
                    Answer: `We will require personal details such as your addresses for the last 2 years, employment status and your personal income. If you want to transfer balances from your other credit or store cards, you'll need to have these credit card / store card numbers and balances available.`
                },
                3: {
                    Heading: `Why do you need to know how long I've been at my address?`,
                    Answer: 'If you have been at your address less than 2 years, we will ask you for a previous address to aid us in establishing your financial background.'
                },
                4: {
                    Heading: 'Is it safe to apply for a {{PARTNER}} credit card online?',
                    Answer: `Yes – we use a 'secure session' using SSL encryption technology whenever sending personal information over the internet, which at present is the most secure encryption available.`
                },
                5: {
                    Heading: 'What happens when I have completed my application?',
                    Answer: `After you have completed your application form, we will review your information and make a decision. If you have been accepted, we'll try to tell you straight away. Sometimes we may need more time to consider your application, in which case we will contact you in the next 7–10 working days.`
                },
                6: {
                    Heading: `If I've been accepted, when do I get my card?`,
                    Answer: `From the date you are accepted it will take between 7-10 working days before you will have your credit card. Your PIN will arrive first and separately from your credit card.`
                },
                7: {
                    Heading: 'When will I receive my PIN?',
                    Answer: `You should receive your PIN a few days before you get your card. If you don't receive it within 7 days of being accepted, please {{LINK}} and we'll send you a duplicate.`,
                    Link: { Text: 'contact us', Url: ExternalLinks.CONTACT_US }
                },
                8: {
                    Heading: 'Should I change my PIN number?',
                    Answer: `For security reasons we recommend that you change your PIN number to a number that's easy to remember, but not easy for others to guess. You can change your PIN at any UK high street bank cash machine displaying the Mastercard or Visa logo.`
                },
                9: {
                    Heading:
                        'I have been declined for a credit card before - does this mean {{PARTNER}} will decline me?',
                    Answer: 'Not necessarily. {{PARTNER}} takes all the information you provide on your application form and a decision is then made based on that and the information from your credit history.'
                },
                10: {
                    Heading: 'What if my application gets declined?',
                    Answer: 'It is normally best to wait a few months before you apply again for a {{PARTNER}} credit card. However, our QuickCheck tool enables you to find out if you will be accepted, so you can return at any time to check your eligibility.'
                },
                11: {
                    Heading: 'What are VISA and Mastercard?',
                    Answer: 'VISA and Mastercard own the international systems that allow you to use your card in more than 22 million outlets worldwide. You can use your card wherever you see a VISA or Mastercard symbol, as shown on your card.'
                }
            }
        },
        FinalChecks: {
            Heading: 'About our final checks',
            TriggerText: 'About our final checks',
            Paragraph1:
                'Based on the upfront checks of your credit history we do before you apply, you’ve got a yes from us.',
            Paragraph2: 'If you finish your application, we still need to:',
            ListItem1:
                'Do our fraud and identity checks. To do this, we share your information with fraud prevention agencies and other parties. Depending on what comes back, there’s a small chance we won’t be able to open your account.',
            ListItem2:
                "Let the credit reference agencies know you've applied for a credit card. This will go on your credit file, where other lenders and companies can see you’ve applied to open an account with us."
        },
        EditStage: {
            Heading: "We'll need to run another QuickCheck",
            TriggerText: "Edit '{{name}}'",
            Paragraph1:
                "Play it safe and change what's wrong in QuickCheck. Then, we'll give you a fresh yes or no based on your new information.",
            Paragraph2: 'If you carry on with wrong details, our final checks could stop us giving you a card.',
            Paragraph1LineGuarantee:
                'Spotted something wrong? You’ll need to change it before finishing your application. Once you’ve updated what you need to, we’ll give you a fresh yes or no based on your new details.',
            Paragraph2LineGuarantee:
                'Just so you know, starting a new QuickCheck means we can’t promise you’ll be offered a card again, or that you’ll get a guaranteed credit limit.',
            Proceed: 'Change my details in QuickCheck',
            Return: 'Return to my application'
        },
        BTTerms: {
            Heading: 'Balance Transfer',
            TriggerText: 'Balance transfer terms and conditions',
            Paragraphs: {
                1: 'You can only transfer balances from other UK accounts and these should not be in arrears or over their credit limit.',
                2: 'We cannot accept transfers from debit cards, or any credit cards issued by Capital One, including those with partner brands, for example, Luma, Ocean, thinkmoney, ASOS, Post Office (card number starting 518581), Very and Littlewoods.',
                3: 'The amount you request to be balance transferred from your other credit card(s) to Capital One can be the current balance plus the value of any purchases still to be charged. If you are intending to make a payment within the next few days on your card(s) then deduct this from the amount you want to transfer.',
                4: 'Approval of your balance transfer request is dependent on your Capital One credit card application being approved.',
                5: 'We will make the balance transfers in the order of importance you have specified on your application form. If the credit limit we set is lower than the balance transfer(s) you have requested then we may not transfer a balance(s). The maximum amount you can transfer depends on how much of your credit limit is available.',
                6: 'Once the balance transfer(s) are complete you may wish to close your other credit card account(s). Remember, any outstanding difference between the amount we have transferred and the remaining balance of that account must be paid by you. You should continue to pay at least the minimum payment on it until your balance is cleared.'
            }
        }
    },
    HiddenText: {
        NewTab: ', opens in a new tab',
        NewWindow: ', opens in a new window',
        PopupWindow: ', opens in a popup window'
    },
    Modal: { CloseButtonAriaLabel: 'Close pop-up', Close: 'close' },
    BTInput: { BINInput: 'Card number', TransferAmount: 'Transfer amount' }
};

export default content;
