export const isGreaterThanEqualToZero = (value) => value !== null && Number.parseInt(value, 10) >= 0;

export const isGreaterThanZero = (value) => value !== null && Number.parseInt(value, 10) > 0;

// We're using the teaser months to recognise if a product is BT
// Teaser months currently returned as number by product service, but for robustness other checks have been made
export const isBTProduct = (agreementData) => !!agreementData?.product?.isBT;

export const isBTRequested = (agreementData) => !!agreementData?.product?.btRequested;

export const isInstantIssuance = (agreementData) => !!agreementData?.product?.isInstantIssuance;
