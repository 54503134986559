import { CapitalOneLogoPrimaryDark, Flex, Box } from '@cof/plastic-components';
import PropType from 'prop-types';

import Logo from '../Logo';
import AggregatorLogo from './AggregatorLogo';
import { useJourneyContext } from '../../data/JourneyContext';

const LogoComponent = ({ showAggregatorLogo = false, centerLogoOnDesktop }) => {
    const { agreementData } = useJourneyContext();
    const hasSelectedProduct = !!agreementData?.product;
    return (
        <Flex
            width="100%"
            height="100%"
            justifyContent={centerLogoOnDesktop ? ['flex-start', null, null, 'center'] : ['center', null, 'flex-start']}
            alignItems="center"
            data-qa-id="logo-container"
            paddingLeft={centerLogoOnDesktop && hasSelectedProduct ? ['sm', null, null] : null}
        >
            {showAggregatorLogo ? (
                <Box style={{ borderRight: '1px', paddingRight: '10px', borderColor: '#000', borderStyle: 'solid' }}>
                    <CapitalOneLogoPrimaryDark />
                </Box>
            ) : (
                // to keep the error page consistent this needs to passed through to make the post office logo the correct size on the error & timeout pages
                <Logo centerLogoOnDesktop={centerLogoOnDesktop} />
            )}
            <AggregatorLogo />
        </Flex>
    );
};

LogoComponent.propTypes = {
    showAggregatorLogo: PropType.bool,
    centerLogoOnDesktop: PropType.bool.isRequired
};

export default LogoComponent;
