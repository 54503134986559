import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        heading1: COLORS.global.white,
        heading2: COLORS.global.blue750,
        heading3: COLORS.capitalOne.teal450,
        heading4: COLORS.capitalOne.blue750,
        iconColor: COLORS.capitalOne.teal600,
        background: COLORS.capitalOne.blue750,
        borderedBoxColor: COLORS.global.grey50,
        errorHeading: COLORS.global.white,
        error: COLORS.capitalOne.red550,
        linkColor: COLORS.global.white,
        linkColorDark: COLORS.capitalOne.teal600,
        listItem: COLORS.global.white,
        listIcon: COLORS.global.white,
        modalHeading: COLORS.capitalOne.blue750,
        text: COLORS.global.white,
        scrollBoxBorder: COLORS.global.grey200,
        styledInfoIcon: COLORS.capitalOne.teal600,
        otherThingsSection: {
            listIcon: COLORS.global.blue750
        },
        loadingSpinner: {
            background: COLORS.capitalOne.blue750,
            spinner: COLORS.global.white
        },
        label: {
            background: COLORS.capitalOne.blue750
        },
        outerContainer: {
            border: COLORS.capitalOne.teal450
        },
        product: {
            titleLine1: COLORS.global.white,
            titleLine2: COLORS.global.white,
            circle: COLORS.capitalOne.teal450,
            bulletPoints: COLORS.global.white,
            icons: COLORS.capitalOne.teal450,
            lockIconColor: COLORS.capitalOne.yellow250,
            background: '#27314A',
            cardName: COLORS.global.white
        },
        modal: {
            links: 'light'
        },
        readFollowing: {
            background: COLORS.global.grey50
        },
        section: {
            heading: {
                background: '#27314A'
            }
        },
        circle: {
            background: COLORS.capitalOne.teal450
        },
        checkDetails: {
            iconColor: COLORS.global.white,
            textColor: COLORS.global.white,
            editStageButtonVariant: 'secondaryLink',
            stage: {
                background: '#27314A',
                textColor: COLORS.global.white,
                iconColor: COLORS.global.white
            },
            accordion: {
                variant: 'light',
                textColor: COLORS.global.white,
                iconColor: COLORS.global.white,
                separatorColor: '#27314A'
            }
        },
        signatureBlock: {
            iconColor: COLORS.capitalOne.blue750,
            background: COLORS.global.grey50,
            textVariant: 'light',
            textColor: COLORS.capitalOne.blue750,
            checkbox: COLORS.capitalOne.teal600,
            border: COLORS.global.transparent
        },
        productChoice: {
            textColor: COLORS.global.white,
            aprExampleBackground: COLORS.capitalOne.blue750
        }
    }
};
