export const PREFERENCE = {
    ONLINE_STATEMENTS: 'online-statements',
    MARKETING_CONTACT_PERMISSION: 'marketing-contact-permission'
};

export const BRANDS = ['capitalOne', 'luma', 'littlewoods', 'ocean', 'postoffice', 'thinkmoney', 'very', 'asos'];

export const PCR_DEFAULT = 9652602104;

export const HOSTED_PARTNERS = ['luma', 'littlewoods', 'very', 'asos'];

export const MISSING_DETAILS_DISABLED_CONSUMERS = ['QCHK', 'LUMA', 'LTWD', 'VERY', 'ASOS'];

export const CHARACTERS = {
    tab: 9,
    enter: 13,
    escape: 27,
    spacebar: 32,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40
};

export const ERROR_TYPE = {
    CLIENT_ERROR: 'ClientError',
    LOAD_AGREEMENT: 'LoadAgreement',
    SUBMIT_AGREEMENT: 'SubmitAgreement',
    EDIT_REQUEST: 'EditRequest'
};

export const CARD_TYPES = {
    CLASSIC_CARD_TYPE: 'Everyday Spend',
    COMPLETE_CARD_TYPE: 'Complete',
    BALANCE_TRANSFER_CARD_TYPE: 'Balance Transfer'
};

export const isAggregator = (consumerName) =>
    ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK', 'MSE', 'CCC'].includes(consumerName);
