import { ApplicationLayout, LoadingSpinnerPortal } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';
import PropType from 'prop-types';
import { lazy, Suspense, useEffect } from 'react';
import { useTheme } from 'styled-components';

import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import Header from './components/Header';
import LoadingSpinner from './components/LoadingSpinner';
import PrintProvider from './components/PrintProvider';
import TransformedCookieBanner from './components/TransformedCookieBanner';
import { useJourneyContext } from './data/JourneyContext';
import useExperiment from './hooks/useExperiment/useExperiment';
import { BRANDS, ERROR_TYPE } from './utilities/constants';
import { sendNewRelicEvent } from './utilities/newRelic';
import onCookieManagementEvent from './utilities/onCookieManagementEvent';
import setNewRelicAttributes from './utilities/setNewRelicAttributes';
import { getSearchParameter } from './utils';

const ErrorRender = ({ aggregatorConsumerName = '' }) => (
    // eslint-disable-next-line react/no-unstable-nested-components
    <ApplicationLayout Header={() => <Header aggregatorConsumerName={aggregatorConsumerName} />} Footer={Footer}>
        <TechnicalError />
    </ApplicationLayout>
);

ErrorRender.propTypes = {
    aggregatorConsumerName: PropType.string
};

const showLoadingSpinner = ({ hasError, gettingAgreementData }) => !hasError && gettingAgreementData;
const showAgreementForm = ({ hasError, gettingAgreementData, agreementData }) =>
    !hasError && !gettingAgreementData && agreementData;

export const showCookieBanner = ({ hasError, gettingAgreementData }) => !gettingAgreementData || hasError;

const HiddenForm = lazy(() => import('./components/HiddenForm'));
const TechnicalError = lazy(() => import('./pages/TechnicalError'));

const App = () => {
    const theme = useTheme();
    const {
        agreementData,
        error: agreementDataError,
        sessionId,
        loading: gettingAgreementData,
        consumerName,
        productId,
        vendorCode,
        aggregatorConsumerName,
        brand,
        vendorCodeFromUrl
    } = useJourneyContext();

    const [, setExperimentVariant] = useExperiment();
    const hasError = !getSearchParameter('quotation') || agreementDataError;
    const { isEnabled: gtmIsEnabled } = config.get('gtm');

    useEffect(() => {
        if (agreementData) {
            sendNewRelicEvent('Agreement Data Loaded');
        }
        if (sessionId) window?.newrelic?.setUserId(sessionId);
        const experimentVariant = agreementData?.experimentVariant;
        if (experimentVariant) {
            setExperimentVariant(experimentVariant);
        }
        setNewRelicAttributes({
            brand,
            experimentVariant,
            vendorCodeFromUrl,
            productId,
            consumerName,
            vendorCode
        });
    }, [setExperimentVariant, brand, sessionId, agreementData, vendorCodeFromUrl, productId, consumerName, vendorCode]);

    // eslint-disable-next-line react/no-unstable-nested-components
    const Spinner = () => (
        <LoadingSpinnerPortal
            bg={theme.agreement.loadingSpinner.background}
            color={theme.agreement.loadingSpinner.spinner}
            domElementId="getting-agreement-spinner"
            zIndex={100}
            LoadingSpinner={LoadingSpinner}
        />
    );
    return (
        <ErrorBoundary renderOnError={<ErrorRender aggregatorConsumerName={aggregatorConsumerName} />}>
            <ApplicationLayout Header={() => {}} Footer={Footer}>
                <PrintProvider>
                    {showLoadingSpinner({ hasError, gettingAgreementData }) && <Spinner />}
                    <Suspense fallback={<Spinner />}>
                        {showCookieBanner({ hasError, gettingAgreementData }) && (
                            <TransformedCookieBanner
                                allowList={BRANDS}
                                id="cookie-banner"
                                onCookieManagementEvent={onCookieManagementEvent}
                                isGtmEnabled={gtmIsEnabled}
                            />
                        )}
                        {hasError && <TechnicalError errorType={ERROR_TYPE.LOAD_AGREEMENT} />}
                        {showAgreementForm({ hasError, gettingAgreementData, agreementData }) && <HiddenForm />}
                    </Suspense>
                </PrintProvider>
            </ApplicationLayout>
        </ErrorBoundary>
    );
};

export default App;
