import { Header as PlasticHeader } from '@cof/plastic-components';
import PropType from 'prop-types';

import styled, { css } from 'styled-components';
import LogoComponent from './LogoComponent';
import { useJourneyContext } from '../../data/JourneyContext';

const hasAggregatorLogo = (consumerName) =>
    ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK'].includes(consumerName);

export const StyledHeader = styled(PlasticHeader)`
    ${({
        theme: {
            components: { header },
            space
        },
        showAggregatorLogo,
        centerLogoOnDesktop
    }) => css`
        background-color: ${showAggregatorLogo
            ? header.colors.aggregators.background
            : header.colors.background.desktop};
        border: ${showAggregatorLogo ? header.colors.aggregators.border : header.colors.border.desktop};
        padding: ${centerLogoOnDesktop ? `0px ${space.sm}` : null};
    `};
`;

// Logo needs to be centered on mobile for error pages and timedout, but on the left for agreement
const Header = ({ centerLogoOnDesktop } = { centerLogoOnDesktop: false }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const showAggregatorLogo = hasAggregatorLogo(aggregatorConsumerName);

    return (
        <StyledHeader showAggregatorLogo={showAggregatorLogo} centerLogoOnDesktop={centerLogoOnDesktop}>
            <LogoComponent showAggregatorLogo={showAggregatorLogo} centerLogoOnDesktop={centerLogoOnDesktop} />
        </StyledHeader>
    );
};

Header.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    appForm: PropType.bool,
    centerLogoOnDesktop: PropType.bool
};

export default Header;
