/* eslint complexity: ["error", 7] */
import config from '@cof/ukweb-config/lib/clientConfig';
import axios from 'axios';
import { useEffect, useState } from 'react';

import getConfig from './config';

const applyArgToAxiosConfig = (arg, axiosConfig) => {
    let resultConfig = typeof axiosConfig === 'function' ? axiosConfig(arg) : axiosConfig;
    if (typeof resultConfig === 'string') {
        resultConfig = { url: resultConfig };
    }

    return resultConfig;
};

const useRequestCallback = (axiosConfigOrFactory, axiosConfigOverrides = {}) => {
    const [state, setState] = useState({
        execCount: 0,
        execInput: null,
        loading: false,
        error: null,
        response: null,
        data: null
    });

    const exec = (arg) =>
        setState({
            ...state,
            loading: true,
            error: null,
            execInput: arg,
            execCount: state.execCount + 1
        });

    useEffect(() => {
        if (!state.execCount) {
            return;
        }

        async function doRequest() {
            const requestConfig = {
                ...applyArgToAxiosConfig(state.execInput, axiosConfigOrFactory),
                ...axiosConfigOverrides
            };

            try {
                const response = await axios(requestConfig);
                setState({ ...state, loading: false, response, data: response.data });
            } catch (error) {
                setState({ ...state, loading: false, error });
            }
        }

        doRequest();
        // eslint-disable-next-line
    }, [state.execCount]);

    const retry = () => exec(state.execInput);
    const { loading, error, response, data, execCount, execInput } = state;

    return [exec, loading, { error, retry, response, data, execCount, input: execInput }];
};

export const usePostCallback = (axiosConfigOrFactory) => useRequestCallback(axiosConfigOrFactory, { method: 'POST' });

export const useAgreementData = (params) => {
    const [agreementData, setAgreementData] = useState(null);
    const [gettingAgreementData, setGettingAgreementData] = useState(true);
    const [agreementDataError, setAgreementDataError] = useState(null);
    const { quotation, sessionId, brand, pcr, sParameter, v, product, channel, campaign } = params;
    const { isEnabled: brandMatchEnabled } = config.get('brandMatch') || {};
    const { downstreamVersionIsEnabled } = config.get('useDownstreamVersion') || {};

    useEffect(() => {
        if (!quotation) {
            return;
        }

        const agreementSession = sessionStorage.getItem('agreementData');
        let validSession = !!agreementSession;
        let parsedSession;

        if (validSession) {
            try {
                parsedSession = JSON.parse(agreementSession);
                setAgreementData(parsedSession);
                setGettingAgreementData(false);
            } catch {
                validSession = false;
            }
        }

        const queryParams = { quotation, sessionId, pcr, s: sParameter, p: product, v, channel, campaign };
        if (brandMatchEnabled) queryParams.b = brand;
        if (document.referrer) queryParams.referrer = document.referrer;
        const agreementParams = { withCredentials: true, params: queryParams };

        if (!validSession) {
            const agreementInstance = axios.create(getConfig('agreement'));
            agreementInstance
                .get('', agreementParams)
                .then((response) => {
                    const { data } = response;
                    if (data) {
                        sessionStorage.setItem('agreementData', JSON.stringify(data));
                        setAgreementData(data);
                    }
                })
                .catch((err) => {
                    setAgreementDataError(err);
                })
                .finally(() => setGettingAgreementData(false));
        }
    }, [
        quotation,
        sessionId,
        pcr,
        sParameter,
        product,
        v,
        brand,
        brandMatchEnabled,
        channel,
        downstreamVersionIsEnabled,
        campaign
    ]);

    return [agreementData, gettingAgreementData, agreementDataError, setAgreementData];
};

export const submitAgreement = async (data) => {
    let response;
    let unknownError = false;
    let invalidBINsError = false;
    try {
        const { baseURL, timeout } = getConfig('apply');
        response = await axios.post(baseURL, data, { timeout });
    } catch (e) {
        response = e?.response;
        if (response?.data?.reason === 'INVALID_BINS') {
            invalidBINsError = true;
        } else {
            unknownError = true;
        }
    }
    return { unknownError, invalidBINsError, response };
};

export const getDisclosures = async (productId, agreementToken, sessionId) => {
    const { baseURL, timeout } = getConfig('disclosures');
    try {
        const result = await axios.post(baseURL, { agreementToken, productId, sessionId }, { timeout });
        return result ? result.data : null;
    } catch {
        return null;
    }
};

/**
 * build up the request to the edit endpoint with the configuration inside of the config and then execute with
 * axios
 */
export const useEditSubmit = () => usePostCallback((data) => ({ ...getConfig('edit'), data }));
